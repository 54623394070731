@import "../../../vendor/variables";

.intro{  
    position: relative;
    background: #FDC093;

    &__wrapper{  
        max-width: 1640px;
        margin: auto; 
        padding: 54px 40px 54px 40px;
        background: #FDC093;
        display: flex;
        align-items: center;    
        justify-content: space-between;  
    }

    &__title {
        margin: 100px auto 0 auto;
        font-weight: 900;
        font-size: 96px;
        line-height: 120%;
        letter-spacing: 0.175em;
    }

    &__text {
        margin: 0;
        padding-top: 28px;
        max-width: 662px;
        font-weight: 400;
        font-size: 24px;
        line-height: 30px;
    }   

    &__container {
        margin-top: 86px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 40px;
    }

    &__form {
        height: 55px;
        background-color: #F2F0EB;
        border: 1px solid #F2F0EB;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        gap:40px;
        align-items: center;

    }

    &__input {       
        background-color: inherit;
        border: none;
        width: 400px;
        height: 50px;
        padding-left: 15px;
        color: rgba(26, 3, 18, 0.5);
    }
    
    &__button {
        width: 48px;
        height: 48px;
        background-image: url(../../../img/search-icon.svg);
        background-position: center;
        background-color: inherit;
        background-repeat: no-repeat;
        border: none;
        margin-right: 10px;
    }

    &__button_red {   
        width: 240px;
        height: 57px;        
        background: #E32A19;
        
    }
}

@media screen and (max-width: 1700px) {
    .intro{
        &__image{
            max-height: 400px;
        }
    }    
}


@media screen and (max-width: 1440px) {
    .intro{
        &__title{
            font-size: 86px;
            letter-spacing: 0.15em;
        }

        &__container{
            justify-content: left;
        }
    }    
}

@media screen and (max-width: 1160px) {
    .intro{
        &__wrapper {
            justify-content: center;
            padding: 54px 210px 54px 210px;
            gap: 40px;
        }

        &__container {
            margin-top: 40px;
            flex-direction: column-reverse;
            align-items: flex-start;
            gap: 24px;        
        }

        &__title{
            margin: 50px auto 0 auto;
            font-weight: 900;
            font-size: 36px;
            line-height: 20px;
            letter-spacing: 0.15em;
            text-align: left;

        }

        &__text{
            max-width: 230px;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            text-align: left;
        }

        &__image{
            max-height: 240px;
        }

    }    
}

@media screen and (max-width: 768px) {
    .intro{
        &__wrapper {
            padding: 54px 100px 54px 100px;
            gap: 20px;
        }
    }
}

@media screen and (max-width: 660px) {
    .intro{
        &__wrapper {
            flex-direction: column;
            gap: 80px;           
        }

        &__container_text {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__container {
            align-items: center;
        }

        &__text{
            text-align: center;
        }
       
    }
}


@media screen and (max-width: 450px) {
    .intro{
        &__wrapper {
            padding: 50px;
        }
    }
}