@import "../../vendor/variables";

.nav{
    margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 53px 40px 0 40px;
        max-width: 1640px;
        margin: auto;

    &__links{
        display: flex;
        flex-direction: row;
        gap: 40px;
    }

    &__link {  
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(26, 3, 18, 0.5);
    }

    &__link_active {       
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $colorText;
        text-decoration-line: underline;
    }

    &__form {
        height: 55px;
        border: 1px solid #1A0312;
        border-radius: 8px;
        display: flex;
        flex-direction: row;
        gap:40px;
        align-items: center;

    }

    &__input {       
        background-color: inherit;
        border: none;
        width: 400px;
        height: 50px;
        padding-left: 15px;
        color: rgba(26, 3, 18, 0.5);
    }
    
    &__button {
        width: 48px;
        height: 48px;
        background-image: url(../../img/search-icon.svg);
        background-position: center;
        background-repeat: no-repeat;
        border: none;
        margin-right: 10px;
    }
}

@media screen and (max-width: 1300px) {
    .nav {
        max-width: 1140px;
        gap: 20px;
        
        &__links{
            gap: 20px;
        }
    }
}


@media screen and (max-width: 950px) {
    .nav {
        padding-left: 80px;
        padding-right: 80px;
        gap: 20px;
        
        &__links{
            gap: 20px;
        }
    }
}


@media screen and (max-width: 640px) {
    .nav {
        padding-top: 10px;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        gap: 45px;
    }
}

@media screen and (max-width: 455px) {
    .nav {
        padding-left: 36px;
        padding-right: 36px;
    }
}