@import "../../vendor/variables";

.aboutProject {

    &__container {
        position: relative;
    }

    &__owl {
        position: absolute;
        right: 250px;
        bottom: -220px;
        z-index: 0;
    }

    &__sfera {
        width: 100%;
        display: block;
        margin-top: -1px;
    }

    &__sfere {
        width: 100%;
        display: block;
        margin-bottom: -1px;
    }
}

@media screen and (max-width: 1920px) {
    .aboutProject{
        &__owl {
            right: 57px;
          }
    }    
}

@media screen and (max-width: 1700px) {
    .aboutProject{
        &__owl {
            width: 650px;
            height: 650px;
            bottom: -180px;
          }
    }    
}

@media screen and (max-width: 1160px) {
    .aboutProject{
        &__owl {
            width: 192px;
            height: 192px;
            bottom: -50px;
            right: 140px;
          }
    }    
}

@media screen and (max-width: 768px) {
    .aboutProject{
        &__owl {
            right: 70px;
          }
    }    
}
