.footer {
  min-height: 350px;
  background-color: rgba(89, 2, 66, 1);

  width: 100%;

  &__contacttext {
    color: white;
    margin: 0 0 6px 0;
    text-transform: uppercase;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
  }
  &__links {
    margin: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  &__space {
    height: 100%;
    gap: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__logoContainer {
    display: flex;
    gap: 20px;
  }
  &__social {
    justify-content: center;
    align-items: center;
    display: flex;
    gap: 24px;
  }
  &__textLogo {
    color: white;
    font-size: 24px;
    font-weight: 900;
    line-height: 20px;
    letter-spacing: 0.15em;
    text-align: left;
  }

  &__click{
    text-decoration: none;
  }

  &__contact {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  &__link {
    text-transform: uppercase;
    text-decoration: none;
    color: white;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    &:hover {
      text-decoration: underline;
      transform: scale(1.02);
    }

  }

  &__container {
    width: 100%;
    padding-top: 67px;
    align-items: center;
    max-width: 1080px;
    display: flex;
    margin: 0 auto;
    justify-content: space-between;
  }

  &__email {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: white;
    text-decoration: none;
    display: flex;
    gap: 15px;
    &:hover {
      text-decoration: underline;
      transform: scale(1.02);
    }

    &::before {
      display: block;
      background-image: url(../../img/emailIconWhite.svg);
      content: '';
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &__phone {
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: white;
    text-decoration: none;
    display: flex;
    gap: 15px;

    &:hover {
      text-decoration: underline;
      font-size: 17px;
    }

    &::before {
      display: block;
      background-image: url(../../img/phoneWhite.svg);
      content: '';
      width: 20px;
      height: 20px;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
}
@media screen and (max-width: 980px) {
  .footer {
    padding-bottom: 60px;
    &__container {
      gap: 40px;
      flex-direction: column;
    }
    &__logoContainer {
    order: 1;
    }
    &__links {
      align-items: center;
      order: 3;
      padding: 0;
    }
    &__social {
      order: 2;
    }
    &__contact {
      align-items: center;
      order: 4;
    }
  }
}