.layout {
    max-width: 100%;
    position: relative;
    background: #FDC093;

  &__more{
  box-sizing: border-box;
  padding: 0 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #FDC093;
  max-width: 1640px;
  margin:  0 auto 0 auto;
}


  &__sova {
    position: absolute;
    left: 12%;
    top: -60%;
    width: 277px;
    height: 354px;
  }

}
.callback {
  position: relative;
  background: #FDC093;
 
  &__title {
    margin: 0;
    padding: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 58px;
    text-align: left;
  }
  &__text {
    margin-top: 40px;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    text-align: left;

  }
  &__container {
    max-width: 662px;
  }
  &__button {
    cursor: pointer;
    margin-top: 86px;
    border: none;
    background:  #1A0312;;
    color: rgba(242, 240, 235, 1);
    height: 44px;
    width: 240px;
    border-radius: 8px;
  }

  &__picture {
    max-height: 560px;
  }

  &__bg {
    z-index: 2;
    display: block;
    bottom: 100%;
    width: 100%;
    position: absolute;
    max-height: 100%;
  }
}

@media screen and (max-width: 2340px) {
  .layout {
    &__sova {
      top: -55%;
      left: 9%;
    }
  }
}


@media screen and (max-width: 1440px){
  .layout{
    &__sova {
      display: none;
    }
  }

  .callback {
    &__picture {
      max-height: 460px;
    }
  }
}

@media screen and (max-width: 980px) {
  .callback {
    padding-bottom: 68px;
    &__container {
      flex-direction: column;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &__button {
      margin-top: 48px;
      max-width: 200px;
    }
    &__title {
      text-align: center;
      max-width: 322px;
      margin-top: 20px;
      font-size: 24px;
      line-height: 29px;
    }
    &__text {
      margin-top: 24px;
      text-align: center;
      max-width: 322px;
      font-size: 12px;
      line-height: 14px;
    }
    &__picture {
      max-height: 260px;
    }
    &__sova {
      max-width: 40%;
    }
  }
}
@media screen and (max-width: 640px) {
  .layout {
    &__more{
      flex-direction: column;
      margin-top: 0;
    }
  }
}