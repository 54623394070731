.error{
    height: 100vh;
    padding: 125px 0 143px 0;
    max-width: 1640px;
    margin: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between; 
    
    &__title {
        margin: 0;
        font-weight: 900;
        font-size: 40px;
        line-height: 120%;
        letter-spacing: -0.01em;
    }

    &__text {
        margin: 0;
        padding-top: 40px;
        max-width: 520px;
        font-size: 16px;
        line-height: 120%;
    }

    &__button_red {
        width: 155px;
        height: 55px;
        margin-top: 86px;        
        background: #E32A19;        
    }
}

@media screen and (max-width: 1700px) {
    .error{
        padding: 100px 160px 144px 160px;  

        &__img {
            max-width: 650px;
        }

        &__container {
            max-width: 510px;
        }
    }    
}

@media screen and (max-width: 1420px) {
    .error{
        &__img {
            max-width: 450px;
          }
    }    
}

@media screen and (max-width: 1160px) {
    .error{
        padding: 100px 160px 144px 160px;
        gap: 20px;

        &__container {
            max-width: 335px;
        }

        &__title {
            font-size: 32px;
        }

        &__text {
            padding-top: 20px;
            font-size: 12px;
        }

        &__button_red {
            width: 200px;
            height: 36px;
            margin-top: 40px; 
        }

        &__img {
            max-width: 320px;
          }
    }    
}

@media screen and (max-width: 860px) {
    .error{
        padding: 100px 80px 144px 80px;
    }    
}

@media screen and (max-width: 680px) {
    .error{
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;    
        gap: 70px;
        padding: 75px 36px 144px 36px;

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__title {
            text-align: center;
        }

        &__text {
            text-align: center;
        }
    }    
}
