@import "../../../vendor/variables";
.category{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr));
    column-gap: 40px;
    row-gap: 56px;  
    padding: 0;
    max-width: 1640px;
    margin: auto;
    margin-bottom: 50px;
    margin-top: 124px;
    padding: 0 40px 0 40px;
    list-style-type: none;
    
    &__item {
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 240px;
    }

    &__title{
        margin: 0 auto;
        height: 58px;
        padding-bottom: 20px;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        text-align: center;
        text-transform: uppercase;
    }

    &__link{
        box-sizing: border-box;
        display: inline-block;
        padding: 24px 41px 24px 41px;
        width: 240px;
        height: 246px;
        background-color: #590242;
        border-radius: 8px;
        filter: drop-shadow(-4px 4px 20px rgba(0, 0, 0, 0.09));
    }
    
    &__text{
        margin: 0 auto;
        padding-top: 16px;
        font-weight: 400;
        font-size: 14px;
        line-height: 120%;
        text-align: left;
    }
}

@media screen and (max-width: 1440px) {
    .category {
        grid-template-columns: repeat(auto-fill, minmax(185px, 1fr));

        &__item {        
            max-width: 185px;
        }

        &__link {
            padding: 22px 30px 22px 30px;
            width: 180px;
            height: 200px;
        }
    }
}

@media screen and (max-width: 1080px) {
    .category {
        margin-top: 40px;
        grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
        column-gap: 90px;
        row-gap: 60px;
        padding-left: 160px;
        padding-right: 160px;

        &__item {        
            max-width: 150px;
        }

        &__link {
            padding: 27px 33px 27px 33px;
            width: 150px;
            height: 154px;
        }

        &__image {
            width: 83px;
            height: 103px;
        }

        &__title {
            font-size: 16px;
            padding-bottom: 0;
        }

        &__text {
            hyphens: auto;
            max-height: 100px;
        }
        
    }
}

@media screen and (max-width: 950px) {
    .category {
        padding-left: 80px;
        padding-right: 80px;        
    }
}

@media screen and (max-width: 790px) {
    .category {
        column-gap: 50px;
        padding-left: 36px;
        padding-right: 36px;    
    }
}

@media screen and (max-width: 640px) {
    .category {
        column-gap: 18px;   
    }
}

@media screen and (max-width: 390px) {
    .category {
          display: flex;
          flex-direction: column;
          align-items: center;
          column-gap: 28px;
    }
}