.photoPopup { 
    display: none;

    &__opened {
        position: fixed;
        background: rgba(0,0,0,0.8);
        z-index: 1;
        justify-content: center;
        align-items: center;
        right: 0;
        top: 35px;
        left:0;
        width: 100%;
        height: 100%;    
        transition: visibility .3s, opacity .3s;
        display: flex;
    }

    &__container {
        position: relative;
        width: 1082px;
        height: 880px;
        background: #590242;
        border-radius: 20px;  
    }

    &__close {
        position: absolute;
        top: 47px;
        right: 46px;
        border: none;
        box-sizing: border-box;
        background: url(../../../img/close.svg) no-repeat center;
        width: 26px;
        height: 26px;
        cursor: pointer;
    }

    &__wrapper {        
        width: 100%;
        height: 100%;
        background-image: url(../../../img/hexagons.svg);
        background-repeat: no-repeat;
        background-position: top 95px left 220px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__image {
        margin-top: 154px;
        width: 388px;
        height: 388px;
        border-radius: 20px;
    }

    &__title {
        margin: 0;
        padding-top: 36px;
        font-style: italic;
        font-weight: 800;
        font-size: 30px;
        line-height: 120%;
        color: #F2F0EB;
    }

    &__caption {
        margin: 0;
        padding-top: 20px;
        font-style: normal;
        font-weight: 300;
        font-size: 30px;
        line-height: 120%;
        color: #F2F0EB;
    }

    &__text {
        margin: 0;
        padding: 20px 81px 0 81px;
        font-style: italic;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;     
        text-align: center;
        color: #F2F0EB;
    }
}

@media screen and (max-width: 1500px) {
    .photoPopup {
        &__container {
            width: 635px;
            height: 594px;
        }

        &__close {
            top: 24px;
            right: 24px;
        }

        &__wrapper {
            background-position: top 50px left 120px;
            background-size: 420px;
        }

        &__image {
            margin-top: 90px;
            width: 250px;
            height: 250px;
        }

        &__title {
            padding-top: 24px;
            font-size: 26px;
        }

        &__caption {
            padding-top: 15px;
            font-size: 20px;
        }

        &__text {
            padding: 15px 75px 0 75px;
            font-size: 20px;
        }
    }
}


@media screen and (max-width: 1160px) {
    .photoPopup{       
        &__wrapper {
            background-position: top 87px left 170px;
            background-size: auto;
            background-image: url(../../../img//hexagons-mini.svg);
        }

        &__image {
            margin-top: 115px;
            width: 182px;
            height: 182px;
        }

        &__title {
            font-size: 16px;
        }

        &__caption {
            font-size: 14px;
        }

        &__text {
            font-size: 16px;
        }
    }    
}

@media screen and (max-width: 540px) {
    .photoPopup {
        &__container {
            width: 340px;
            height: 594px;
        }

        &__wrapper {
            background-position: top 87px left 20px;
        }

        &__text {
            padding: 20px 50px 0 50px;
        }
    }
}
