
.documentList {
  display: flex;
  flex-direction: column;
}

.main {
  &__up{
    display: block;
    width: 100%;
    margin-bottom: -1px;
  }

  &__down{
    display: block;
    width: 100%;
    height: 100%;
  }

  &__bg {
    display: block;
    width: 100%;
    position: relative;
    z-index: 1;
    margin-top: -1.7px;
  }

  &__bgTop {
    display: block;
    width: 100%;
    margin-bottom: -1px;
  }

  &__bgBottom {
    display: block;
    width: 100%;
    margin-top: -1px;
    margin-bottom: 80px;
  }
}

@media screen and (max-width: 2340px) {
  .main {
    &__sova {
      bottom: -202%;
    }
  }
}

@media screen and (max-width: 1890px) {
  .main {
    &__sova {
      bottom: -200%;
    }
  }
}
