.container {
  margin: 0 auto;
  max-width: 1400px;

}
.containerArrows {
  gap: 20px;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 120px;

  &__wrapper{
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
  }

}
.pagesNumber {
  order: 3;
}

@media screen and (max-width: 425px) {
  .containerArrows {
    flex-direction: column-reverse;
    padding: 40px 5px 0 5px;
    margin-top: 0;
    margin-bottom: 60px;
  }    
}

.navContainer {
        display: flex;
        padding: 53px 40px 0 40px;
        max-width: 1640px;
        margin: auto;
}

@media screen and (max-width: 880px) {
  .navContainer {
    padding: 20px 20px 0 20px;
  }    
}

@media screen and (max-width: 425px) {
  .navContainer {
    padding: 20px 0 0 0;
  }    
}