@import "../../vendor/variables";

.btn {    
    border: none;
    border-radius: 10px;
    color :#F2F0EB;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    cursor: pointer;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.25);
    &:hover {
        font-weight: 800;
        background: rgba(89, 2, 66, 1);
    }
    &:active {
        font-weight: 800;
        background-color: rgba(26, 3, 18, 1);
    }
}

@media screen and (max-width: 1080px){
    .btn {
        border-radius: 5px;
        font-size: 12px;
        line-height: 14px;
    }
}