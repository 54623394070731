@import "../../vendor/variables";

.guide{
    background-color: #590242;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    &__container{
        position: relative;
        max-width: 1640px;
        margin: 0 auto;
    }
    &__up {
        display: block;
        bottom: 100%;
        width: 100%;
        position: absolute;
        max-height: 100%;
    }

    &__down {
        display: block;
        top: 100%;
        width: 100%;
        position: absolute;
        max-height: 100%;
    }
    &__title {
        @include titleSection;
        padding: 16px 0 12px 0;
        margin: 0;
        color: white;
        text-align: left;
    }

    &__text {
        margin: 0;
        padding-bottom: 40px;
        font-weight: 600;
        font-size: 24px;
        line-height: 120%;
        color: white;
        text-align: left;
    }

    &__sova {
        position: absolute;
        top: -3%;
        right: 10%;
    }

    &__content {
        display: block;
        margin: 0;
        position: relative;        
    }

    &__play {
        background-image: url(../../img/play.svg);
        background-repeat: no-repeat;
        background-size: contain;
        background-color: inherit;
        background-position: center;
        cursor: pointer;
        padding: 0;
        width: 68px;
        height: 55px;
        border: none;
        position: absolute;
        top: 42%;
        right: 48%;

        &_invisible{
        background-image: none;
        background-color: inherit;
        padding: 0;
        width:58px;
        height: 47px;
        border: none;
        position: absolute;
        top: 42%;
        right: 50%;
       
        }
    }

    &__video {
        max-width: 100%;
        filter: drop-shadow(-4px 4px 20px rgba(0, 0, 0, 0.25));
        border-radius: 20px;
        margin: 20px 0 44px 0;
    }

    &__ptenchik{
        display: none;
    }
}

@media screen and (max-width: 1700px) {
    .guide{
        &__container {
            max-width: 1200px;
        }

        &__sova {
            width: 236px;
            height: 260px;
            top: 7%;
            right: 8%;
        }

        &__play {
            top: 43%;
            right: 47%;
            width: 81px;
            height: 81px;
        }
    }
}

@media screen and (max-width: 1245px) {
    .guide {
        &__container {
            max-width: 940px;
        }

        &__title {
            font-weight: 800;
            font-size: 32px;
            line-height: 120%;
        }

        &__text {
            font-weight: 600;
            font-size: 16px;
            line-height: 120%;
        }

        &__sova {
            top: 0;
        }

        &__play {
            top: 43%;
            right: 46%;
            width: 81px;
            height: 81px;
        }

    }
}

@media screen and (max-width: 980px) {
    .guide {
        &__container {
            max-width: 640px;
        }

        &__sova {
            width: 133px;
            height: 146px;
            top: 13%
        }

        &__play {
            top: 42%;
            right: 45%;
            width: 60px;
            height: 60px;
        }
}
}

@media screen and (max-width: 640px) {
    .guide {

        &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 40px;
        }


        &__sova {
           display: none;
        }

        &__title {
            text-align: center;
        }

        &__text {
            text-align: center;
        }

        &__ptenchik {
            display: block;
            padding-bottom: 46px;
          
        }
    }
}

@media screen and (max-width: 425px){
    .guide{
        &__play {
            top: 33%;
            right: 42%;
        }
    }
}

@media screen and (max-width: 376px){
    .guide{
        &__play {
            top: 30%;
            right: 40%;
        }
    }
}