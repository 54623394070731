.success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__title {
        color: white;
        font-size: 24px;
        font-weight: 700;
        line-height: 29px;
        text-align: center;
    }
    &__text {
        color: white;
        font-size: 18px;
        font-weight: 400;
        line-height: 23px;
        text-align: center;
    }
}

.formPopup { 
    display: none;
    &__opened {
        position: fixed;
        background: rgba(0,0,0,0.8);
        z-index: 1;
        justify-content: center;
        align-items: center;
        right: 0;
        top:30px;
        left:0;
        width: 100%;
        height: 100%;    
        transition: visibility .3s, opacity .3s;
        display: flex;
    }
    &__content {
        position: relative;
        width: 816px;
        height: 728px;
        background: #590242;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &__close {
        position: absolute;
        top: 47px;
        right: 46px;
        border: none;
        box-sizing: border-box;
        background: url(../../img/close.svg) no-repeat center;
        width: 26px;
        height: 26px;
        cursor: pointer;
    }
    &__wrapper {
        display: flex;
        flex-direction: column;
        max-width: 620px;
    }
    &__title {
        margin: 20px 0 30px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 24px;
        line-height: 29px;
        text-align: center;
        color: #F2F0EB;
    }
    &__text {
        height: 158px;
        padding: 10px;
        border: none;
        border-radius: 8px;
    }
    &__input {
        height: 35px;
        padding: 10px;
        margin-top: 30px;
        background: #F2F0EB;
        border: none;
        border-radius: 8px;
    }
    &__container_check {
        display: flex;
        align-items: center;
        gap: 10px;
        margin-top: 30px;
    }
    &__checkbox:checked {
        width: 24px;
        height: 24px;
        background-color: #F2F0EB;
        background-image: url(../../img/304167-9c27b0.svg);
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        
    }
    
    &__checkbox:checked::before {
        //content: "\2713";
        color: #FFFFFF;
        font-size: 16px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    
    &__checkbox {
        width: 24px;
        height: 24px;
        border: 3px solid #F2F0EB;
        cursor: pointer;
    }
    &__captcha{
        margin: 0 auto;
        width: 304px;
        height: 70px;
    }
    &__link {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #F2F0EB;
    }
    &__link_accent{
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 19px;
        text-decoration-line: underline;
        color: #F2F0EB;
        border: none;
        cursor: pointer;
    }
    &__button_white {
        margin-top: 30px;
        margin-left: 20%;
        width: 240px;
        height: 44px;
        background: #F2F0EB;
        color:#590242;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        &:hover {
            font-weight: 700;
            background: #F2F0EB;
            color:#590242;
        }
        &:disabled {
            border: 1px solid #959292;
            background: rgba(89, 2, 66, 1);
            color: #959292;
        }
        &:active {
            font-weight: 700;
            background-color: rgba(26, 3, 18, 1);
            color: #F2F0EB;
          }
    }
}
@media screen and (max-width: 1700px) {
    .formPopup{
        &__content {
            width: 700px;
            height: 700px;
        }
        &__close {
            top: 25px;
            right: 25px;
        }
        &__text {
            height: 138px;
        }
        &__input {
            margin-top: 20px;
            height: 30px;
        }
        &__container_check {
            margin-top: 20px;
            margin-bottom: 10px;
        }
    }
}
@media screen and (max-width: 1160px) {
    .formPopup{
        &__title{
            margin: 30px 0 30px 0;
        }
        &__content {
            width: 635px;
            height: 620px;
        }
        &__close {
            top: 25px;
            right: 25px;
        }
    }
}
@media screen and (max-width: 580px) {
    .formPopup{
        &__title{
            font-size: 18px;
            margin: 10px 0 5px 0;
        }
        &__content {
            height: 580px;
            padding: 0 10px 20px 10px;
        }
        &__close {
            top: 15px;
            right: 15px;
            width: 18px;
            height: 18px;
        }

        &__button_white {
            margin-top: 15px;
            margin-bottom: 0;
            margin-left: 15%;
        }
        &__link{
            font-size: 14px;
            margin: 0;
        }
    }
}
@media screen and (max-width: 325px) {
    .formPopup {
        &__button_white {
            margin-left: 10%;
        }
    }
}