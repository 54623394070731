.prevButton, .nextButton {
  width: 44px;
  height: 40px;
  border-radius: 10px;
  display: block;
  background-position: center;
  background-repeat: no-repeat;
}
.nextButton {
  order: 1;
  background-color: black;
  background-image: url(../../img/arrows/arrow.svg);
  cursor: pointer;
}
.prevButton {
  order: 2;
  background-image: url(../../img/arrows/arrow_black.svg);
  cursor: pointer;
}

.downloadButton {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 18px;
    cursor: pointer;
    width: 190px;
    height: 44px;
    border: none;
    background: #1A0312;
    border-radius: 8px;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.09);
    color: white;
    font-weight: 500;
      &:after {
        content: '';
        width: 19px;
        height: 15px;
        background-image: url(../../img//download-2.svg);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
      }

      &:hover {
        font-weight: 700;
        background: rgba(89, 2, 66, 1);
      }

      &:active {
        font-weight: 700;
        background-color: rgba(26, 3, 18, 1);
    }
}