@import "../../vendor/variables";

.links {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 40px;
    list-style-type: none;

    &__main {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(26, 3, 18, 0.5);
        text-decoration: none;

    }

    &__text {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $colorText;
        text-decoration-line: underline;
    }
}

@media screen and (max-width: 1160px) {
    .links {
        &__main{
            font-size: 12px;
            line-height: 120%;
        }         

        &__text {
            font-size: 12px;
            line-height: 120%;
        }
    }    
}

@media screen and (max-width: 768px) {
    .links {
        gap: 20px;
        padding: 0;
        justify-content: left;
        text-align: center;
    }    
}

@media screen and (max-width: 425px) {
    .links {
        gap: 10px;
        padding: 0 20px 0 20px;
        justify-content: left;
        text-align: center;
    }    
}

@media screen and (max-width: 375px) {
    .links {
        gap: 10px;
        padding: 0 10px 0 10px;
        justify-content: left;
        text-align: center;
    }    
}