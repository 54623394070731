.react-pdf__Page__canvas {
    display: block;
    margin: 50px auto 0  auto;

}
.react-pdf__Page__annotations {
    display: block;
    margin: 0 auto;

}
@media screen and (max-width: 880px) {
    .test {

        height: 100%;
    }
    .pdfContainer {

    }
    .react-pdf__Page__canvas {
        box-sizing: border-box;
        padding: 0 20px;
        display: block;
        margin: 0 auto;
        width: 100vw !important;
        height: auto !important;
    }

    @media screen and (max-width: 375px) {
        .react-pdf__Page__canvas {
            padding: 0 10px;
        }    
    }


    .react-pdf__Page__annotations {
        display: block;
        margin: 0 auto;
        width: 100vw !important;
        height: auto !important;
    }
} 