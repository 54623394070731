@import "../../vendor/variables";

.notfound{    
    &__wrapper {
        max-width: 1640px;
        margin: 130px auto 160px auto;
        margin-bottom: 160px;
        display: flex;
        flex-direction: row-reverse;
        gap: 40px;
        align-items: center;
        justify-content: center;
    }

    &__title {
        margin: 0 auto;       
        @include title
    }

    &__text {
        margin-top: 64px;
        font-weight: 400;
        font-size: 24px;
        line-height: 120%;
    }

    &__list {
        margin-top: 20px;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;
        color: #1A0312;
      
    }

   
}

@media screen and (max-width: 1180px) {
    .notfound{
        &__wrapper {
            margin-top: 85px;
            margin-bottom: 87px;
            gap: 80px;
        }

        &__text {
            max-width: 400px;
            margin-top: 40px;
        }

        &__list {
            padding-left: 15px;
        }
    }   
}

@media screen and (max-width: 980px) {
    .notfound {
        &__wrapper{
            padding-left: 100px;
            padding-right: 100px;
            gap: 40px;
        }
        &__img {
            max-height: 180px;
        }

        &__title {
            @include title_960
        }

        &__text {
            font-size: 20px;
        }

        &__list {
            font-size: 12px;
        }
    }
}

@media screen and (max-width: 650px) {
    .notfound {
        &__wrapper {
            margin-top: 50px;
            margin-bottom: 0;
            padding-left: 36px;
            padding-right: 36px;
            flex-direction: column;
        }

        &__title {
            font-size: 25px;
            text-align: center;
        }

        &__text {
            max-width: 320px;
            margin-top: 40px;
            text-align: center;
        }

        &__list {
            max-width: 284px;
        }
    }
   
}