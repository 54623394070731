@font-face {
  font-family: 'Exo 2.0';
  src: url('Exo20-Regular.eot');
  src: local('Exo 2.0 Regular'), local('Exo2.0-Regular'),
  url('Exo20-Regular.eot?#iefix') format('embedded-opentype'),
  url('Exo20-Regular.woff') format('woff'),
  url('Exo20-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2.0';
  src: url('Exo20-Medium.eot');
  src: local('Exo 2.0 Medium'), local('Exo2.0-Medium'),
  url('Exo20-Medium.eot?#iefix') format('embedded-opentype'),
  url('Exo20-Medium.woff') format('woff'),
  url('Exo20-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2.0';
  src: url('Exo20-Thin.eot');
  src: local('Exo 2.0 Thin'), local('Exo2.0-Thin'),
  url('Exo20-Thin.eot?#iefix') format('embedded-opentype'),
  url('Exo20-Thin.woff') format('woff'),
  url('Exo20-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2.0 Extra';
  src: url('Exo20-ExtraLight.eot');
  src: local('Exo 2.0 Extra Light'), local('Exo2.0-ExtraLight'),
  url('Exo20-ExtraLight.eot?#iefix') format('embedded-opentype'),
  url('Exo20-ExtraLight.woff') format('woff'),
  url('Exo20-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
}




@font-face {
  font-family: 'Exo 2.0';
  src: url('Exo20-Bold.eot');
  src: local('Exo 2.0 Bold'), local('Exo2.0-Bold'),
  url('Exo20-Bold.eot?#iefix') format('embedded-opentype'),
  url('Exo20-Bold.woff') format('woff'),
  url('Exo20-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
}


@font-face {
  font-family: 'Exo 2.0 Semi';
  src: url('Exo20-SemiBold.eot');
  src: local('Exo 2.0 Semi Bold'), local('Exo2.0-SemiBold'),
  url('Exo20-SemiBold.eot?#iefix') format('embedded-opentype'),
  url('Exo20-SemiBold.woff') format('woff'),
  url('Exo20-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2.0';
  src: url('Exo20-Light.eot');
  src: local('Exo 2.0 Light'), local('Exo2.0-Light'),
  url('Exo20-Light.eot?#iefix') format('embedded-opentype'),
  url('Exo20-Light.woff') format('woff'),
  url('Exo20-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Exo 2.0';
  src: url('Exo20-Black.eot');
  src: local('Exo 2.0 Black'), local('Exo2.0-Black'),
  url('Exo20-Black.eot?#iefix') format('embedded-opentype'),
  url('Exo20-Black.woff') format('woff'),
  url('Exo20-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}


@font-face {
  font-family: 'Exo 2.0 Extra';
  src: url('Exo20-ExtraBold.eot');
  src: local('Exo 2.0 Extra Bold'), local('Exo2.0-ExtraBold'),
  url('Exo20-ExtraBold.eot?#iefix') format('embedded-opentype'),
  url('Exo20-ExtraBold.woff') format('woff'),
  url('Exo20-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
}
