@import "../../vendor/variables";

.about {
    box-sizing: border-box;
    padding: 0 40px;
    margin-top: 212px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  
    &__layout {
      margin-bottom: 124px;
      display: flex;
      width: 100%;
      justify-content: center;
      max-width: 1640px;
      gap: 40px;
    }
  
    &__wrapper {
      max-width: 800px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  
    &__link {
      margin: 86px 0 0 0;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      text-decoration: none;
      color: white;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 240px;
      height: 44px;
      background: #1A0312;
      border-radius: 8px;
      box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.25);
      &:hover {
        font-weight: 800;
        background: rgba(89, 2, 66, 1);
      }
    }
  
    &__text {
      max-width: 800px;
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
    }
  
    &__title {
      padding: 0;
      margin: 0 0 56px 0;
      font-size: 64px;
      font-weight: 800;
      line-height: 77px;
      text-align: left;
    }
  }

  @media screen and (max-width: 980px) {
    .about {
      margin: 173px 0 0 0;
  
      &__link {
        margin: 28px 0 0 0;
      }
  
      &__wrapper {
        max-width: 317px;
      }
  
      &__image {
        max-height: 220px;
      }
  
      &__text {
        margin: 0 0 20px 0;
        font-size: 12px;
        line-height: 14.4px;
      }
  
      &__title {
        @include titleSection;
        margin: 0 0 24px 0;
      }
  
      &__layout {
        max-width: 596px;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 40px;
      }
    }
}

@media screen and (max-width: 640px) {
    .about {
      margin-top: 74px;
    }
}

@media screen and (max-width: 640px) {
    .about {
      margin-top: 74px;
  
      &__layout {
        align-items: center;
        flex-direction: column-reverse;
      }
  
      &__title {
        text-align: center;
      }
  
      &__link {
        margin-top: 48px;
      }
  
      &__wrapper {
        align-items: center;
      }
    }
  }
