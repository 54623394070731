@import "../../vendor/variables";
.pagination {
  margin-top: 58px;
  cursor: pointer;
  list-style: none;
  display: flex;
  gap: 27px;
  align-items: center;
  justify-content: center;
  &__previousDisabled {
    border: 1px solid #999999;
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
  &__active {
    border-radius: 10px;
    padding: 12px 8px;
    font-size: 16px;
    background-color: #FDC09380;
  }
  &__previuos{
    background-image: url(../../img/previous.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
  &__next {
    background-image: url(../../img/next.svg);
    background-position: center;
    background-repeat: no-repeat;
  }
}
.documentList {
  max-width: 1640px;
  margin: 0 auto 100px auto;

  &__title {
    margin: 0 auto;
    margin-bottom: 60px;
    margin-top: 30px;
    text-align: center;
    @include title
  }

  &__list {
    align-items: flex-start;
    gap: 39px;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1700px) {
  .documentList {
    max-width: 1080px;
    &__list {
      gap: 27px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .documentList {
    max-width: 820px;
    &__list {
      justify-content: center;
    }
  }
}


@media screen and (max-width: 980px) {
  .documentList {
    padding: 0 40px;

    &__title {
      margin: 0 0 19px 0 ;
      max-width: 640px;
      margin: 0 auto;
      @include  title_960
    }
    &__list {
      column-gap: 40px;
      justify-content: center;
      align-items: center;
    }
  }
}

@media screen and (max-width: 640px) {
  .documentList {
    padding-bottom:  40px;
    &__title {
      text-align: center;
    }
    &__list {

    }
  }
}