@import "../../../vendor/variables";

.team{
    padding-bottom: 119px;
    background-color: #FDC093;

    &__title {
        padding-top: 59px;
        margin: 0 auto 80px auto;
        text-align: center;
        @include title;
        max-width: 1640px;
        padding-left: 0;
    }

    &__list {
        list-style-type: none;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
        column-gap: 35px;
        row-gap: 65px;
        padding: 0;
        max-width: 1340px;
        margin: auto;
        cursor: pointer;
    }

    &__member_foto {
        max-width: 240px;
        height: 248px;
        border-radius: 22px;
        box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.09);
    }

    &__member_caption {
        margin: 0 auto;
        padding-top: 30px;   
        font-style: italic;
        font-weight: 550;
        font-size: 20px;
        line-height: 120%;
        text-align: center;
    }
}

@media screen and (max-width: 1440px) {

}

@media screen and (max-width: 1160px) {
    .team{

        &__title {
            margin: 0; 
            margin-bottom: 24px;           
            @include title_960;
        }

        &__list{
            max-width: 1340px;
            column-gap: 20px;
            row-gap: 32px;
            padding-left: 100px;
            padding-right: 100px;            
        }

        &__member_foto {
            width: 149px;
            height: 154px;
        }

        &__member_caption {
            padding-top: 15px; 
            font-weight: 550;
            font-size: 12px;
            line-height: 120%;
        }
    }    
}

@media screen and (max-width: 768px) {
    .team {
        &__list{            
            padding-left: 80px;
            padding-right: 80px;            
        }
    }
}

@media screen and (max-width: 540px) {
    .team {
        padding-bottom: 70px;

        &__list{
            column-gap: 16px;         
            padding-left: 22px;
            padding-right: 22px;
            padding-bottom: 50px;       
        }

        &__title {
            padding-top: 0;
        }
    }
}