@import "../../vendor/normalize";
@import "../../vendor/font/font.scss";
body {

  background-color: rgba(242, 240, 235, 1);
}
.page {
  display: block;
  text-decoration: none;
  padding: 0; 
  width: 100%;
  min-width: 320px;
  color: #1A0312;
  font-family: 'Exo 2.0', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -moz-text-size-adjust: 100%;
}
