@import "../../../vendor/variables";

.aboutUs{
    &__content{
        max-width: 1640px;
        margin: auto;
        padding: 0 40px 86px 40px;
    }

    &__links{
        display: flex;
        flex-direction: row;
        gap: 40px;
    }

    &__link {      
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: rgba(26, 3, 18, 0.5);
    }

    &__link_active {       
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: $colorText;
        text-decoration-line: underline;
    }

    &__container {
        margin: 0 auto;
        margin-top: 86px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 80px;
    }

    &__title {
        margin: 0;
        @include title;
    }

    &__text {
        margin: 0;
        margin-top: 56px;
        font-weight: 400;
        font-size: 20px;
        line-height: 120%;
    }

}

@media screen and (max-width: 1160px) {
    .aboutUs{
        max-width: 840px;   

        &__container {
            margin-top: 24px;
            gap: 60px;
        }

        &__img{
            max-height: 240px;
        }

        &__title {
            @include title_960;
        }
s
        &__text {
            margin-top: 24px;
            font-size: 12px;
            line-height: 120%;
        }

        &__link {      
            font-size: 12px;
            line-height: 120%;
        }
    
        &__link_active {       
            font-size: 12px;
            line-height: 120%;
        }
    }
  }

  @media screen and (max-width: 920px) {
    .aboutUs{
        &__links {
            padding: 0;
        }

        &__container {
            gap: 30px;
            padding: 0;
        }
    }
  }


  @media screen and (max-width: 768px) {
    .aboutUs{
        &__container {
            flex-direction: column-reverse;
        }

        &__title {
            text-align: center;
        }
    }
  }

  @media screen and (max-width: 540px) {
    .aboutUs{
        &__content {
            padding: 0 40px 74px 40px;
        }
    }
  }