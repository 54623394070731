.button__position {
  margin-left: auto;
  display: flex;
  justify-content: right;
  margin-top: 40px;
  right: 0;
  text-underline: none;
  text-decoration: none;
  width: fit-content;
  color: #190312;
}

.button__more {
  text-align: left;
  cursor: pointer;
  padding: 0;
  background-position: bottom;
  background-image: url(../../img/buttonMoreBefore.svg);
  border: none;
  position: relative;
  width: 240px;
  height: 46px;
  text-align: left;
  background-repeat: no-repeat;
  background-size: contain;
}