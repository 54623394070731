@import "../../vendor/variables";

.templateLib{
    &__title{        
        margin: 0 auto;
        text-align: center;
        margin-top: 115px;
        @include title;
    }

    &__sfera {
        display: block;
        width: 100%;
    }
}
   
@media screen and (max-width: 1080px) {
    .templateLib {
        &__title {
            margin-top: 100px;
            @include title_960;
        }
    }    
}

@media screen and (max-width: 640px) {
    .templateLib {
        &__title {
            margin-top: 45px;
        }
    }    
}