@import "../../vendor/variables";

.layout {
  max-width: 1640px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 1;
  position: relative;
  background-color: #590242;
}

.features {
  padding-bottom: 157px;
  background-color: #590242;
  display: flex;
  width: 100%;
  position: relative;

  &__sova {
    position: absolute;
    z-index: 0;
    bottom: 86%;
    max-width: 419px;
    left: 2%;
  }
  &__index {
    background: #590242;
    z-index: 100;
  }
  &__bg {
    display: block;
    z-index: 2;
    bottom: 100%;
    width: 100%;
    position: absolute;
    max-height: 100%;
  }

  &__bgBottom {
    display: block;
    z-index: 3;
    top: 100%;
    width: 100%;
    position: absolute;
    max-height: 100%;
  }

  &__title {
    @include titleSection;
    padding: 134px 0 124px 0;
    margin: 0;
    color: white;
    text-align: left;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 56px;
    color: white;
    padding: 0;
    list-style: none;

    &Item {
      justify-content: flex-start;
      display: flex;
      align-items: center;
      gap: 40px;
      max-width: 815px;

      &Title {
        padding: 0;
        font-weight: 800;
        font-size: 24px;
        line-height: 120%;
      }

      &Text {
        padding: 0;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
      }
    }
  }
}

@media screen and (max-width: 1700px) {
  .features {
    &__images {
      max-width: 520px;
      margin-top: 20%;
    }

  }
  .layout {
    align-items: center;
    justify-content: center;
    padding: 0 40px;
  }
}

@media screen and (max-width: 1200px) {
  .features  {
    &__images {
      max-width: 420px;
    }
  }
}

@media screen and (max-width: 980px) {
  .layout {
    overflow: hidden;
  }
  .features {

    &__title {
      padding: 0;
      margin-top: 50px;
    }

    &__list {
      max-width: 320px;
    }

    &__sova {
      bottom: 92%;
      max-width: 224px;
    }

    &__listItem {
      &Image {
        max-width: 65px;
      }

      &Title {
        font-size: 14px;
        line-height: 17px;
      }

      &Text {
        font-size: 12px;
        line-height: 14px;

      }
    }

    &__images {
      max-width: 343px;
    }
  }
}

@media screen and (max-width: 980px) {
  .layout {
    display: flex;
    flex-direction: column-reverse;
  }
  .features {
    &__title {
      text-align: center;
      margin-bottom: 36px;
    }
    &__images {
      display: none;
    }
  }
}