
.cursor {
  margin: -10px;
  background: #D9D9D9;
  mix-blend-mode: difference;
  position: fixed;
  top: 0;
  left: 0;
  width: 27px;
  height: 27px;
  border-radius: 50%;
  transform: translate(-50%, -50%);
  pointer-events: none;

  z-index: 9999;
  &:active {

      width: 50px;
      height: 50px;
      border-width: 4px;
      opacity: 0.5;
  }
}
.hovered {
  position: fixed;
  top: 0;
  left: 0;
  width: 50px;
  height: 50px;
  background: #D9D9D9;
  border-radius: 50%;
  transform: translate(-50%, -50%);

}