.search__form{
    position: relative;
  }
  
  .search__input_modif {
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.25);
    appearance: none;
    background-color: white;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 120%;
    color: rgba(26, 3, 18, 0.5);
    padding-left: 13px;
    border-radius: 8px ;
    outline: none;
    border: none;
    width: 420px;
    height: 55px;
    &Error {
      color: transparent;
      bottom: -25px;
      position: absolute;
    }
    &:focus {
      color: black;
    }
    &::-webkit-search-cancel-button {
      display: none;
    }  
  }
  
  .search_button {
    padding: 0;
    cursor: pointer;
    position: absolute;
    top: 4px;
    right: 13px;
    border: none;
    background-color: transparent;
    background-image: url(../../img/search.svg);
    background-size: contain;
    background-repeat: no-repeat;
    width: 48px;
    height: 48px;
    &_error {
      display: none;
    }
  }

  @media screen and (max-width: 1030px) {
    
    .search__input_modif {
      max-width: 320px;
    }
  }
  
  @media screen and (max-width: 980px) {

   .search__form_modif {
      position: fixed;
      display: flex;
      display: none;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background-color: #FDC093;
   }
    
    .search__input_modif {
      line-height: 14px;
      max-height: 36px;
    }
    
  
    .search_button {
      top: 5px;
      width: 26px;
      height: 26px;
    }
  }

  
  