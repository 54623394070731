@import "../../vendor/variables";
@import "src/vendor/buttons.module";

.main {
    padding: 110px 40px 0 40px;
    position: relative;
    background-color: $beige;
  
    &__layout {
      display: flex;
      width: 100%;
      max-width: 1640px;
      gap: 188px;
      padding-bottom: 109px;
    }
  
    &__bg {
      display: block;
      top: 100%;
      width: 100%;
      position: absolute;
      max-height: 100%;
    }
  
    &__shablon {
      display: flex;
      max-width: 800px;
      gap: 40px;
    }

    &__image {
      overflow: hidden;
      max-height: 480px;
    }
  
    &__button {
        box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.25);
        border-radius: 8px;
        font-weight: 600;
        text-decoration: none;
        display: flex;
        align-items: center;
        justify-content: center;
    
        &:hover {
          font-weight: 800;
          background: rgba(89, 2, 66, 1);
        }
    
        &:active {
          font-weight: 800;
          background-color: rgba(26, 3, 18, 1);
        }
    
        @extend %button_orange;
      }
      display: flex;
      justify-content: center;
      align-items: center;
    
    &__title {
        @include titleSection;
        letter-spacing: 0.195em;
        margin: 84px 0 0 0;
    }
    
    &__about {
        margin: 28px 0 86px 0;
        font-size: 24px;
        font-weight: 400;
        line-height: 30px;
        text-align: left;
        max-width: 644px;
    }    
}


    @media screen and (max-width: 1700px) {
        .main {
          &__title {
            font-weight: 900;
            font-size: 86px;
            line-height: 83px;
            margin: 84px 0 0 0;
          }
      
          &__layout {
            max-width: 1360px;
            gap: 0;
            justify-content: space-between;
            align-items: center;
          }
      
          &__about {
            margin: 28px 0 0 0;
          }
      
          &__image {
            max-height: 410px;
          }
      
          &__shablon {
            margin-top: 86px;
          }
        }
    }

    @media screen and (max-width: 1335px) {
      .main {
        &__title {
          font-size: 76px;
          line-height: 73px;
        }

        &__image {
          max-height: 310px;
        }
      }
    }


    @media screen and (max-width: 1024px) {
      .main {
        &__image {
          max-height: 280px;
        }

        &__title {
          margin: 0;
        }
      }
    }


    @media screen and (max-width: 980px) {
        .main {
          padding: 68px 40px 0 40px;

            &__button {
              font-size: 12px;
              font-weight: 600;
              line-height: 14px;
              text-align: center;
              height: 36px;
              width: 200px;
            }
        
            &__title {
              font-size: 36px;
              line-height: 20px;
            }
        
            &__layout {
              padding: 0 0 77px 0;
              max-width: 596px;
              gap: 40px;
              justify-content: flex-start;
        
            }
        
            &__about {
              max-width: 231px;
              margin: 8px 0 0 0;
              font-size: 12px;
              line-height: 20px;
              text-align: left;
            }

            &__image {
                max-height: 218px;
            }          
              
            &__shablon {
                gap: 20px;
                margin-top: 40px;
                flex-direction: column-reverse;
            }
            }
    }

    @media screen and (max-width: 660px) {
        .main {
          &__image {
            max-height: 218px;
          }
        }
      }

      @media screen and (max-width: 640px) {
        .main {
          &__container {
            display: flex;
            flex-direction: column;
            align-items: center;
          }
      
          &__layout {
            align-items: center;
            flex-direction: column;
          }
      
          &__title {
            margin: 0 0 8px 0;
          }      
      
          &__shablon {
            margin: 40px 0 0 0;
            display: flex;
            align-items: center;
          }
        }
      }