@import '../../vendor/variables';

.dataPolicy {
  max-width: 1640px;
  margin: auto;

  &__navContainer {
    display: flex;
    justify-content: space-between;
    padding: 53px 40px 0 40px;
    max-width: 1640px;
    margin: auto;
  }

  &__conainer {
    margin-top: 100px;
    margin-bottom: 37px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
  }

  &__title {
    margin: 0;
    text-align: start;
    @include title;
  }

  &__text {
    max-width: 1640px;
    margin: auto;
    margin: 0 0 65px 0;
    padding-left: 40px;
    padding-right: 40px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
  }

  &__table {
    border: 1px solid grey;
  }
}

@media screen and (max-width: 1440px) {
    .dataPolicy {
      &__navContainer {
        padding-left: 80px;
        padding-right: 80px;
    }

      &__text {
        padding-left: 80px;
        padding-right: 80px;
    }

    &__button_black {
        margin: 0 auto 65px 80px;
      }
    

    &__text {
      padding-left: 80px;
      padding-right: 80px;
    }

    &__button_black {
      margin: 0 auto 65px 80px;
    }
  }
}


@media screen and (max-width: 1160px) {
    .dataPolicy {
      max-width: 840px;

    &__conainer {
        margin-top: 30px;
    }

    &__title {
        max-width: 430px;
        @include title_960;
    }

    &__image {
        width: 158px;
        height: 158px;
    }

    &__text {
        margin: 0 0 65px 0;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 1.5;
    }
  }
}

@media screen and (max-width: 830px) {
  .dataPolicy {
    &__navContainer {
      padding-left: 40px;
      padding-right: 40px;
    }

    &__text {
      padding-left: 40px;
      padding-right: 40px;
    }

    &__button_black {
      margin: 0 auto 45px 40px;
      width: 200px;
      height: 36px;
    }
  }
}

@media screen and (max-width: 720px) {
  .dataPolicy {
    &__image {
      display: none;
    }

    &__title {
      font-size: 24px;
      line-height: 120%;
      text-align: center;
    }
  }
}

@media screen and (max-width: 425px) {
  .dataPolicy {
    &__navContainer {
      justify-content: center;
      padding-left: 20px;
      padding-right: 20px;
    }

    &__text {
      padding-left: 20px;
      padding-right: 20px;
    }

    &__button_black {
      margin: 0 auto 35px 20px;
    }
  }
}
