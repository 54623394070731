@import "../../vendor/variables";

.layout {
  max-width: 1640px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 40px;
  width: 100%;
}
.header {
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  height: 64px;
  top: 0;
  position: fixed;
  width: 100vw;
  z-index: 100;
  background-color: $beige;

  &__search {
    display: flex;
    flex-direction: row;
    gap: 30px;
  }

  &__menuImage{
    display: none;
  }

  &__close {
    display: none;
  }

  &__menu {
    display: none;
  }

  &__phone {
    display: none;
  }
  
  &__contact {
    padding: 0;
    margin: 0;
    display: flex;
    list-style: none;
    flex-direction: column;
    gap: 13px;
   
    &_email {
      display: flex;
      gap: 15px;
      text-decoration: none;
      color: #1A0312;
      &::before {
        display: block;
        background-image: url(../../img/emailIcon.svg);
        content: '';
        width: 20px;
        height:  20px;
      }
    }
    &_phone {
      display: flex;
      gap: 15px;
      text-decoration: none;
      color: #1A0312;
      &::before {
        display: block;
        background-image: url(../../img/phone.svg);
        content: '';
        width: 20px;
        height:  20px;
      }
    }
  }

  &__nav {
    gap: 50px;
    font-weight: normal;
    justify-content: space-between;
    &_container {
      margin: 0;
      padding: 0;
      display: flex;
      gap: 70px;
      list-style: none;
      justify-content: center;
    }
    &Item {
      box-sizing: border-box;
      margin: 0;
      text-decoration: none;
       width: 198px;
      @include navLinkSizeAndColor();
      &:hover {
        text-decoration: underline;
        transform: scale(1.02);
      }


      &_active {
        @include navLinkSizeAndColor();
        font-weight: bold;
        &:hover {

        }
      }
    }
  }

}

.search__button {
  padding: 0;
  cursor: pointer;
  border: none;
  background-color: transparent;
  background-image: url(../../img/search.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
}

@media screen and (max-width: 1700px) {
  .layout {
    z-index: 200;
    max-width: 1360px;
  }
}

@media screen and (max-width: 1030px) {
  .header {
    &__search {
      align-items: center;
    }
    &__nav {
        &_container {
          gap: 30px;
          padding: 0 20px;
        }
      }
    }
  
 
  .search__button {
    width: 45px;
    height: 45px;
  }
}


@media screen and (max-width: 980px) {
  .layout {
    max-width: 596px;
    padding: 0 0 0 40px;
  }
  .header {
    height: 58px;
    width: 100%;

    &__phone {
      display: block;
    }
    &__menuImage{
      display: flex;
    }
    &__contact {
      display: none;
    }

    &__logo{ 
      margin-left: 73%;
    }


    &__nav {
      align-items: center;
      display: none;
      z-index: 500;
      flex-direction: column;
      position: fixed;
      width: 100%;
      height: 100%;
      background-image: url(../../img/bgmobile.svg);
      background-color: rgba(26, 3, 18, 1);
      top: 0;
      right: 0;
      text-align: center;
      padding: 100px 0;
      transition: .7s;
      background-repeat: no-repeat;
      &_container {
        margin-top: 116px;
        padding: 0;
        gap: 18px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
      &Item {
        color: white;

        &_active {
          color: white;
        }
      }
      &_open {
        display: flex;
        justify-content: flex-start;
      }
    }
    &__close {
      cursor: pointer;
      background-repeat: no-repeat;
      border: none;
      outline: none;
      background-color: transparent;
      z-index: 1000;
      display: flex;
      left: 0;
      top: 0;
      width: 32px;
      height: 32px;
      background-image: url(../../img/burger.svg);
      &__open {
        background-size: contain;
        background-image: url(../../img/closee.svg);
      }
    } 
    &__search_modal{
      position: fixed;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      top: 0;
      right: 0;
      background-color: #FDC093;
    } 

    &__search_relative{
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 108px;
      margin-top: 50px;
    }

    &__modal_close{
      position: absolute;
      top: -10px;
      left: -75%;
      background-image: url(../../img/blackkrest.svg);
      background-color: transparent;
      cursor: pointer;
      background-repeat: no-repeat;
      border: none;
      width: 32px;
      height: 32px;
    }
  }
}

@media screen and (max-width: 768px){
  .layout {
    padding: 0 40px;
  }

  .header {
    &__modal_close{
      top: -10px;
      left: -50%;
    }
  }
}

@media screen and (max-width: 425px){
  .header {
    &__modal_close{
      top: -10px;
      left: 1%;
    }
    
  }
}
