.search_form {
  position: relative;
}
.search__input {
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.25);
  appearance: none;
  background-color: white;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: rgba(26, 3, 18, 0.5);
  padding-left: 13px;
  border-radius: 8px ;
  outline: none;
  border: none;
  width: 520px;
  height: 55px;
  &Error {
    color: red;
    top: -20px;
    position: absolute;
  }
  &:focus {
    color: black;
  }
  &::-webkit-search-cancel-button {
    display: none;
  }  
}

.search__input_modif{
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.25);
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 120%;
  color: rgba(26, 3, 18, 0.5);
  padding-left: 13px;
  border-radius: 8px;
  outline: none;
  border: 1px solid #1A0312;
  background-color: inherit;
  width: 520px;
  height: 55px;
  &:focus {
    color: black;
  }
  &::-webkit-search-cancel-button {
    display: none;
  }  

}

.search_button {
  padding: 0;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 13px;
  border: none;
  background-color: transparent;
  background-image: url(../../img/search.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  &_error {
    display: none;
  }
}

@media screen and (max-width: 1190px) {
  .search__input {
    font-size: 16px;
    line-height: 14px;
    max-width: 320px;
  }
}

@media screen and (max-width: 980px) {
  .search__input {
    max-height: 36px;
  }

  .search__input_modif {
    line-height: 14px;
    max-height: 36px;
    max-width: 320px;
  }

  .search_button {
    width: 26px;
    top: 5px;
    height: 26px;
  }

}
