//buttons
$button: white;
//nav
$beige: #FDC093;
$colorText: #1A0312;
@mixin navLinkSizeAndColor() {
  text-transform: uppercase;
  line-height: 120%;
  color: #1A0312;
  font-size: 16px;
}
@mixin titleSection() {
  font-weight: 800;
  font-size: 64px;
  line-height: 120%;
  @media screen and (max-width: 980px) {
    font-size: 32px;
    line-height: 38px;
  }
}
/* оранжевый темный */
$orangeDark: #E32A19;
$title: black;
// разрешения
$breakpoint_1440: 1440px;

@mixin title {  
  font-style: normal;
  font-weight: 750;
  font-size: 64px;
  line-height: 120%;
  max-width: 800px;
  color: $colorText;
}

@mixin title_960 {
  font-style: normal;
  font-weight: 800;
  font-size: 32px;
  line-height: 120%;
  color: $colorText;
}