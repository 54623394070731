@import "variables";
%button_orange {
  max-height: 55px;
  min-width: 240px;
  background: $orangeDark;
  cursor: pointer;
  color: #F2F0EB;
  outline: none;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  &:hover, &:focus {

  }


}