@import "../../vendor/variables";

.searchPage {
    &__sfera {
        display: block;
        width: 100%;
    }

    &__navContainer{
        display: flex;
        padding: 53px 40px 0 40px;
        max-width: 1640px;
        margin: auto;
    }
}

@media screen and (max-width: 1440px) {
    .searchPage {

        &__navContainer{
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}


@media screen and (max-width: 830px) {
    .searchPage {
        &__navContainer{
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}



@media screen and (max-width: 425px) {
    .searchPage {
        &__navContainer {
            justify-content: center;
            padding-left: 20px;
            padding-right: 20px;
        }
    }
}

