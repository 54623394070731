@import "../../vendor/variables.scss";

.catPage{ 
    &__sfera {
        display: block;
        width: 100%;
    }

    &__title{        
        margin: 0 auto;
        margin-top: 50px;
        text-align: center;
        @include title
    }

    &__navContainer{
        display: flex;
        padding: 53px 40px 0 40px;
        max-width: 1640px;
        margin: auto;
    }
}


@media screen and (max-width: 1440px) {
    .catPage {

        &__navContainer{
            padding-left: 80px;
            padding-right: 80px;
        }
    }
}


@media screen and (max-width: 1080px) {
    .catPage {
        &__title {
            margin-top: 52px;
            margin-bottom: 38px;
            @include title_960;
        }
    }
}


@media screen and (max-width: 830px) {
    .catPage {
        &__navContainer{
            padding-left: 40px;
            padding-right: 40px;
        }
    }
}



@media screen and (max-width: 425px) {
    .catPage {
        &__navContainer {
            justify-content: center;
            padding: 10px 20px;
        }
    }
}