@import "../../../vendor/variables";

.feedbackForm{
    position: relative;
    background: #FDC093;
    margin: 0;
    padding: 0;

    &__container {
        background: #FDC093;
        display: flex;   
        align-items: center;   
        justify-content: center;  
        gap: 178px;
        padding: 120px 0 120px 0;
        max-width: 1640px;
        margin: auto;
    }
        
    &__title {
        margin: 0;
        max-width: 600px;        
        font-style: normal;
        font-weight: 700;
        font-size: 48px;
        line-height: 120%;       
    }

    &__text {
        margin: 0;
        padding-top: 40px;
        max-width: 660px;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 120%;        
    }

    &__button_black {
        width: 277px;
        height: 45px;
        margin-top: 86px;
        background: #1A0312;
        cursor: pointer;
        
    }
}

@media screen and (max-width: 1440px) {
    .feedbackForm{
        &__container {
            gap: 80px;
            padding: 120px 40px 120px 40px;
        }

        &__img {
            max-height: 420px;
        }

        &__title {
            font-size: 42px;
        }
    }   
}

@media screen and (max-width: 1080px) {
    .feedbackForm{

        &__container {
            padding: 20px 160px 70px 160px;
        }


        &__img {
            width: 220px;
            max-height: 220px;
        }

        &__title {
            max-width: 320px;
            font-size: 24px;
        }

        &__text {
            padding-top: 24px;
            font-size: 12px;
            max-width: 320px;
        }

        &__button_black {
            margin-top: 48px;
            width: 200px;
            height: 36px;
        }
    }   
}

@media screen and (max-width: 950px) {
    .feedbackForm{
        &__container {
            padding: 20px 80px 70px 80px;
        }
    }
}

@media screen and (max-width: 640px) {
    .feedbackForm {
        &__container {
            padding: 20px 80px 16px 80px;
            flex-direction: column;
            gap: 36px;
        }

        &__wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        &__title {
           text-align: center;
        }

        &__text {
            text-align: center;
        }
    }
}

@media screen and (max-width: 455px) {
    .feedbackForm {
        &__container {
            padding: 20px 36px 16px 36px;
        }
    }
}