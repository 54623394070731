.document {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-width: 380px;
  height: 551px;
  border-radius: 20px;
  box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.09);
  background-color: white;
  box-sizing: border-box;

  &__date{
    color: #1A031299;
    font-size: 14px;
    font-weight: 600;
    line-height: 17px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 8px 0;
  }

  &__button {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: center;
    gap: 13px;
    cursor: pointer;
    width: 190px;
    height: 44px;
    border: none;
    background: #590242;
    border-radius: 8px;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.09);
    color: white;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    
    &:hover{
      font-weight: 700;
    }

    &:active {
      font-weight: 700;
      color: white;
      background-color: rgba(26, 3, 18, 1);
    }

    &_download {
      border: 2px solid black;
      background: transparent;
      color: #1A0312;
      &:after {
        content: '';
        width: 19px;
        height: 15px !important;
        background-image: url(../../img/download.svg) !important;
      }
    }
    &:after {
      content: '';
      width: 19px;
      height: 13px;
      background-image: url(../../img/prewiev.svg);
    }
  }

  &__title {
    max-height: 210px;
    margin: 0;
    padding: 31px 7px 8px 7px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    text-transform: uppercase;
    cursor: pointer;
  }

  &__buttons {
    margin-top: 16px;
    padding: 0 14px 31px 14px;
    display: flex;
    gap: 12px;
  }

  &__container {
    background-repeat: no-repeat;
    background-size: cover;
    max-width: 360px;
    height: 100%;
    max-height: 328px;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    margin: 8px 19px 0 19px;
    cursor: pointer;
  }
  &__image {
    z-index: 2;
    width: 396px;
    height: 226px;
  }
}

@media screen and (max-width: 1700px) {
  .document {
    max-width: 330px;
    &:last-child {
      display: none;
    }
  }
}

@media screen and (max-width: 1200px) {
  .document {
    max-width: 330px;
    &:last-child {
      display: flex;
    }
  }
}



@media screen and (max-width: 980px) {
  .document {
    max-width: 320px;
    height: 480px;

    &__button {
      font-size: 12px;
      line-height: 14px;
      width: 150px;
      height: 36px;
      &:after {
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        content: '';
        width: 12px;
        height: 10px;
        background-image: url(../../img/prewiev.svg);
      }
    }
    &__title {
      font-size: 16px;
      line-height: 19px;
    }
  }
}